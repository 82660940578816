import * as React from 'react';
import { SiteSelector as SiteSelectorInterface } from './ComNavConfig';
import { FormattedMessage } from 'react-intl';
import { ArrowDownIcon } from 'styleguide/components/Icons';
import styled, { css } from 'styled-components';
import { media, withWindow } from 'styleguide/helpers';

const Wrapper = styled.div`
	position: relative;
`;

interface TriggerProps {
	open: boolean;
	bgColor?: string;
}

const Trigger = styled.button<TriggerProps>`
	${({ theme }) => theme.typography.navigation};

	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 16px;
	line-height: 1;
	padding: 18px 21px;
	background-color: ${({ bgColor, theme }) =>
		bgColor || theme.colors.navigationSiteSelectorBackground || theme.colors.navigationText};
	color: ${({ theme }) => theme.colors.navigationSiteSelectorText || theme.colors.white};
	border: none;
	width: 100%;
	cursor: pointer;

	&:hover,
	&:active,
	&:focus {
		outline: none;
	}

	${media.desktop<TriggerProps>`
		width: auto;
		background: ${({ theme, open, bgColor }) => (open ? bgColor || theme.colors.navigationText : 'transparent')};
		color: ${({ theme, open }) =>
			open
				? theme.colors.navigationSiteSelectorOpenText || theme.colors.white
				: theme.colors.navigationSiteSelectorText || theme.colors.navigationText};
		font-size: 15px;
		padding: ${({ theme }) => theme.grid.gutterInPx(2)};

		${({ theme, open, bgColor }) => {
			if (open) {
				return css`
					background: ${bgColor || theme.colors.navigationSiteSelectorOpenBackground || theme.colors.navigationText};
					color: ${theme.colors.navigationSiteSelectorOpenText || theme.colors.white};
					border-radius: 4px 4px 0 0;
				`;
			}
			return css`
				background: transparent;
				color: ${theme.colors.navigationSiteSelectorText || theme.colors.navigationText};
			`;
		}}
	`};

	span {
		flex: 1 0 auto;
		text-align: left;
	}

	svg {
		width: 21px;
		height: 21px;
		vertical-align: sub;
		margin: 0 12px 0 0;
		flex: 0 0 21px;
		transition: transform 200ms ease-out;

		${media.desktop`
			width: 26px;
			height: 26px;
			flex: 0 0 26px;
		`};

		&:last-child {
			width: 26px;
			height: 26px;
			flex: 0 0 26px;
			margin: 0 0 0 12px;

			${media.desktop`
				width: 18px;
				height: 18px;
				flex: 0 0 18px;
			`};
		}

		path {
			fill: ${({ theme }) => theme.colors.white};

			${media.desktop<TriggerProps>`
				fill: ${({ theme, open }) =>
					open
						? theme.colors.navigationSiteSelectorOpenText || theme.colors.white
						: theme.colors.navigationSiteSelectorText || theme.colors.navigationText};
			`};
		}

		&.open {
			transform: rotate(180deg);
		}
	}
`;

const Sites = styled.ul<{ bgColor?: string }>`
	margin: 0;
	padding: 0 0 18px;
	list-style: none;
	background-color: ${({ bgColor, theme }) =>
		bgColor || theme.colors.navigationSiteSelectorOpenBackground || theme.colors.navigationText};

	${media.desktop`
		position: absolute;
		right: 0;
		top: 54px;
		padding: 12px 0;
		min-width: 100%;
		border-radius: 0 0 4px 4px;
	`};
`;

const Site = styled.li`
	padding: 8px 18px 8px 54px;

	${media.desktop`
		padding: 8px 16px 8px 54px;
	`};

	a {
		${({ theme }) => theme.typography.navigation};
		color: ${({ theme }) => theme.colors.navigationSiteSelectorOpenText || theme.colors.white};
		text-decoration: none;
		font-size: 16px;
	}
`;

interface Props extends SiteSelectorInterface {
	className: string;
}

const SiteSelector: React.FC<Props> = ({ currentSiteTitleId, icon, sites, bgColor, className }) => {
	if (sites.length === 0) {
		return null;
	}

	const wrapperEl = React.useRef<HTMLDivElement>(null);
	const [open, setOpen] = React.useState(false);

	React.useEffect(() => {
		withWindow(w => {
			w.addEventListener('click', onDocumentClick);
		});

		return () => {
			withWindow(w => {
				w.removeEventListener('click', onDocumentClick);
			});
		};
	}, []);

	const onDocumentClick = (event: MouseEvent) => {
		if (event.target && wrapperEl?.current?.contains(event.target as Node)) {
			return;
		}

		setOpen(false);
	};

	const onToggleMenu = () => {
		setOpen(!open);
	};

	return (
		<Wrapper ref={wrapperEl}>
			<Trigger open={open} className={className} bgColor={bgColor} onClick={onToggleMenu}>
				{icon && icon}
				<span>
					<FormattedMessage id={currentSiteTitleId} />
				</span>
				<ArrowDownIcon className={open ? 'open' : undefined} />
			</Trigger>
			{open && (
				<Sites bgColor={bgColor}>
					{sites.map(({ title, url }) => (
						<Site key={`site-${url}`}>
							<a href={url}>
								<FormattedMessage id={title} />
							</a>
						</Site>
					))}
				</Sites>
			)}
		</Wrapper>
	);
};

export default SiteSelector;

import React from 'react';
import { NavigationItem } from '../../services';
import { NormalIconWrapperProps } from 'styleguide/components/Icons/NormalIcons/NormalIconWrapper';
import { ContactIcon, InstacartIconWithText, SearchIcon, WorldIcon } from 'styleguide/components/Icons';
import { DefaultTheme } from 'styled-components';
import { LogoType } from 'styleguide/components/Logos/Logo/Logo';
import { NavigationLayoutType } from '../../reducers';
import { SiteId } from 'common/components/App/services';

export interface NavWithIcon extends NavigationItem {
	icon: React.ReactElement<NormalIconWrapperProps & React.SVGAttributes<SVGElement>>;
}
export interface NavWithColor extends NavigationItem {
	bgColor: string;
	textColor: string;
}

export interface NavWithIconAndColor extends NavigationItem {
	bgColor: string;
	textColor: string;
	icon?: React.ReactElement<NormalIconWrapperProps & React.SVGAttributes<SVGElement>>;
}

export interface SiteSelector {
	currentSiteTitleId: string;
	icon?: React.ReactElement<NormalIconWrapperProps & React.SVGAttributes<SVGElement>>;
	sites: NavigationItem[];
	bgColor?: string;
}

export type LogoWhiteSpace = 'default' | 'large';
export type LogoSize = 'default' | 'large';

export interface SiteLinkProps {
	activeItemBorderColor: string;
	mobileActiveItemColor: string;
	mobileItemColor: string;
}

export interface NavProps {
	bgColor: string;
	textColor: string;
	topNavBgColor?: string;
	mobileTopNavBgColor?: string;
	logo?: LogoType;
	logoWhiteSpace: LogoWhiteSpace;
	logoSize: LogoSize;
	logoLink?: string;
	shrinkLogoOnScroll?: boolean;
	title?: string;
	hideTitleOnMobile?: boolean;
	links?: NavWithIcon[];
	sectionLinks?: NavWithIconAndColor[];
	mobileSectionLinks?: NavWithIconAndColor[];
	showMobileCountrySitesLink: boolean;
	showNavigationTree: boolean;
	enableSubNavigationSelection: boolean;
	siteSelector?: SiteSelector;
	navLinkProps?: SiteLinkProps;
	mobileDisableActiveLinkStyle?: boolean;
	invertLogo?: boolean;
}

function withoutTrailingSlash(text: string) {
	return text.endsWith('/') ? text.substring(0, text.length - 1) : text;
}

const defaultSiteLinkProps = (theme: DefaultTheme) => ({
	activeItemBorderColor: theme.colors.white,
	mobileActiveItemColor: theme.colors.white,
	mobileItemColor: theme.colors.navigationItemInActive,
});

const comSectionLinks = (theme: DefaultTheme, comSiteUrl: string) => [
	{
		bgColor: theme.colors.brandPrimary,
		textColor: theme.colors.white,
		url: comSiteUrl,
		title: 'component_comnavigation_valiogroup',
	},
	{
		bgColor: theme.colors.white,
		textColor: theme.colors.brandPrimary,
		url: withoutTrailingSlash(comSiteUrl) + '/food-solutions-for-companies/',
		title: 'component_comnavigation_forcompanies',
	},
	{
		url: withoutTrailingSlash(comSiteUrl) + '/countries/',
		title: 'component_comnavigation_countrysites',
		bgColor: theme.colors.navigationHighlight,
		textColor: theme.colors.white,
		icon: <WorldIcon />,
	},
];
const comDefaultMobileLinks = (theme: DefaultTheme, comSiteUrl: string) => [
	{
		url: comSiteUrl,
		title: 'component_comnavigation_valiogroup',
		bgColor: theme.colors.white,
		textColor: theme.colors.white,
	},
	{
		url: withoutTrailingSlash(comSiteUrl) + '/food-solutions-for-companies/',
		title: 'component_comnavigation_forcompanies',
		bgColor: theme.colors.white,
		textColor: theme.colors.white,
	},
];

const navDefaults = (theme: DefaultTheme) => ({
	topNavBgColor: theme.colors.navigationHeaderBackground,
	bgColor: theme.colors.navigationBackground,
	textColor: theme.colors.navigationText,
	logoWhiteSpace: 'default' as LogoWhiteSpace,
	logoSize: 'default' as LogoSize,
	showMobileCountrySitesLink: true,
	showNavigationTree: true,
	enableSubNavigationSelection: true,
});

const oddlyGoodDefaults = (theme: DefaultTheme, isFlattened?: boolean) => ({
	bgColor: isFlattened ? theme.colors.white : theme.colors.navigationBackground,
	textColor: isFlattened ? theme.colors.navigationItemAlternative : theme.colors.navigationText,
	logo: 'oddlygood' as LogoType,
	logoWhiteSpace: 'large' as LogoWhiteSpace,
	logoSize: 'default' as LogoSize,
	shrinkLogoOnScroll: true,
	showMobileCountrySitesLink: false,
	showNavigationTree: true,
	enableSubNavigationSelection: false,
	invertLogo: !isFlattened,
});

const baltiaSiteDefaults = (theme: DefaultTheme, comSiteUrl: string) => ({
	...navDefaults(theme),
	links: [],
	showMobileCountrySitesLink: true,
	navLinkProps: defaultSiteLinkProps(theme),
});

const navigationConfig = (
	theme: DefaultTheme,
	comSiteUrl: string,
	isFlattened?: boolean
): { [key: string]: { [key in NavigationLayoutType]?: NavProps } } => ({
	[SiteId.com]: {
		ValioComGroup: {
			...navDefaults(theme),
			topNavBgColor: theme.colors.navigationHeaderBackground,
			title: 'component_comnavigation_title',
			sectionLinks: comSectionLinks(theme, comSiteUrl),
			mobileSectionLinks: comDefaultMobileLinks(theme, comSiteUrl),
			links: [
				{
					url: withoutTrailingSlash(comSiteUrl) + '/articles/',
					title: 'component_comnavigation_search',
					icon: <SearchIcon />,
				},
			],
			navLinkProps: defaultSiteLinkProps(theme),
		},
		ValioComCompanies: {
			...navDefaults(theme),
			logoLink: '/food-solutions-for-companies/',
			bgColor: theme.colors.white,
			textColor: theme.colors.brandPrimary,
			title: 'component_comnavigation_forcompanies_title',
			sectionLinks: comSectionLinks(theme, comSiteUrl),
			mobileSectionLinks: [
				{
					url: '/',
					title: 'component_comnavigation_valiogroup',
					bgColor: theme.colors.brandPrimary,
					textColor: theme.colors.brandPrimary,
				},
				{
					url: withoutTrailingSlash(comSiteUrl) + '/food-solutions-for-companies/',
					title: 'component_comnavigation_forcompanies',
					bgColor: theme.colors.brandPrimary,
					textColor: theme.colors.brandPrimary,
				},
			],
			links: [
				{
					url: withoutTrailingSlash(comSiteUrl) + '/food-solutions-for-companies/contact-us/',
					title: 'component_comnavigation_contactus',
					icon: <ContactIcon />,
				},
				// {
				// 	url: 'https://valio.service-now.com/international',
				// 	title: 'component_comnavigation_feedback',
				// 	icon: <FeedbackIcon />,
				// 	isExternal: true,
				// },
				{
					url: withoutTrailingSlash(comSiteUrl) + '/food-solutions-for-companies/articles/',
					title: 'component_comnavigation_search',
					icon: <SearchIcon />,
				},
			],
			navLinkProps: {
				activeItemBorderColor: theme.colors.white,
				mobileActiveItemColor: theme.colors.brandPrimary,
				mobileItemColor: theme.colors.navigationItemAlternative,
			},
		},
		ValioComCountrySites: {
			bgColor: theme.colors.navigationSecondaryBackground,
			textColor: theme.colors.white,
			topNavBgColor: theme.colors.navigationHeaderSecondaryBackground,
			mobileTopNavBgColor: theme.colors.navigationHeaderBackground,
			title: 'component_comnavigation_countrysites_title',
			logoWhiteSpace: 'default' as LogoWhiteSpace,
			logoSize: 'default' as LogoSize,
			showNavigationTree: false,
			enableSubNavigationSelection: false,
			showMobileCountrySitesLink: false,
			sectionLinks: comSectionLinks(theme, comSiteUrl),
			mobileDisableActiveLinkStyle: true,
			mobileSectionLinks: [
				{
					url: comSiteUrl,
					title: 'component_comnavigation_valiogroup',
					bgColor: theme.colors.white,
					textColor: theme.colors.brandPrimary,
				},
				{
					url: withoutTrailingSlash(comSiteUrl) + '/food-solutions-for-companies/',
					title: 'component_comnavigation_forcompanies',
					bgColor: theme.colors.brandPrimary,
					textColor: theme.colors.white,
				},
			],
			links: [],
			navLinkProps: defaultSiteLinkProps(theme),
		},
	},
	[SiteId.es]: {
		CountrySite: {
			...navDefaults(theme),
			title: 'component_comnavigation_title',
			sectionLinks: comSectionLinks(theme, comSiteUrl),
			mobileSectionLinks: comDefaultMobileLinks(theme, comSiteUrl),
			links: [
				{
					url: '/es/contacto/',
					title: 'component_comnavigation_contactus',
					icon: <ContactIcon />,
				},
			],
			navLinkProps: defaultSiteLinkProps(theme),
		},
	},
	[SiteId.ge]: {
		CountrySite: {
			...navDefaults(theme),
			title: 'component_comnavigation_title',
			sectionLinks: comSectionLinks(theme, comSiteUrl),
			mobileSectionLinks: comDefaultMobileLinks(theme, comSiteUrl),
			links: [
				{
					url: '/ge/sakontaktoformulari/',
					title: 'component_comnavigation_contactus',
					icon: <ContactIcon />,
				},
			],
			navLinkProps: defaultSiteLinkProps(theme),
		},
	},
	[SiteId.pl]: {
		CountrySite: {
			...navDefaults(theme),
			title: 'component_comnavigation_title',
			sectionLinks: comSectionLinks(theme, comSiteUrl),
			mobileSectionLinks: comDefaultMobileLinks(theme, comSiteUrl),
			links: [
				{
					url: '/polska/opinia/',
					title: 'component_comnavigation_contactus',
					icon: <ContactIcon />,
				},
			],
			navLinkProps: defaultSiteLinkProps(theme),
		},
	},
	[SiteId.se]: {
		CountrySite: {
			...navDefaults(theme),
			title: 'component_comnavigation_title',
			showMobileCountrySitesLink: false,
			sectionLinks: [
				{
					url: '/kontakta-oss/',
					title: 'component_comnavigation_contactus',
					bgColor: theme.colors.brandPrimary,
					textColor: theme.colors.white,
				},
				{
					url: '/foodservice/',
					title: 'component_comnavigation_foodservice',
					bgColor: theme.colors.navigationHighlight,
					textColor: theme.colors.white,
				},
			],
			mobileSectionLinks: [
				{
					url: '/kontakta-oss/',
					title: 'component_comnavigation_contactus',
					bgColor: theme.colors.brandPrimary,
					textColor: theme.colors.white,
				},
				{
					url: '/foodservice/',
					title: 'component_comnavigation_foodservice',
					bgColor: theme.colors.navigationHighlight,
					textColor: theme.colors.white,
				},
			],
			links: [
				{
					url: '/artiklar/',
					title: 'component_comnavigation_search',
					icon: <SearchIcon />,
				},
			],
		},
	},
	[SiteId.us]: {
		CountrySite: {
			...navDefaults(theme),
			enableSubNavigationSelection: false,
			title: 'component_comnavigation_title_usa',
			showMobileCountrySitesLink: false,
			sectionLinks: comSectionLinks(theme, comSiteUrl),
			mobileSectionLinks: comDefaultMobileLinks(theme, comSiteUrl),
			links: [],
			navLinkProps: defaultSiteLinkProps(theme),
		},
	},
	[SiteId.og]: {
		Oddlygood: {
			...oddlyGoodDefaults(theme, isFlattened),
			siteSelector: {
				currentSiteTitleId: 'site_oddlygood_international',
				icon: <WorldIcon />,
				sites: [
					{
						title: 'site_oddlygood_ee',
						url: 'https://oddlygood.com/ee/',
					},
					{
						title: 'site_oddlygood_fi',
						url: 'https://oddlygood.com/fi/',
					},
					{
						title: 'site_oddlygood_nl',
						url: 'https://oddlygood.com/nl/',
					},
					{
						title: 'site_oddlygood_pl',
						url: 'https://oddlygood.com/pl/',
					},
					{
						title: 'site_oddlygood_es',
						url: 'https://oddlygood.com/es/',
					},
					{
						title: 'site_oddlygood_se',
						url: 'https://oddlygood.com/se/',
					},
					{
						title: 'site_oddlygood_uk',
						url: 'https://oddlygood.com/uk/',
					},
				],
			},
		},
	},
	[SiteId.ogUK]: {
		Oddlygood: {
			...oddlyGoodDefaults(theme, isFlattened),
			enableSubNavigationSelection: true,
			siteSelector: {
				currentSiteTitleId: 'site_oddlygood_uk',
				icon: <WorldIcon />,
				sites: [
					{
						title: 'site_oddlygood_international',
						url: 'https://oddlygood.com/',
					},
					{
						title: 'site_oddlygood_ee',
						url: 'https://oddlygood.com/ee/',
					},
					{
						title: 'site_oddlygood_fi',
						url: 'https://oddlygood.com/fi/',
					},
					{
						title: 'site_oddlygood_nl',
						url: 'https://oddlygood.com/nl/',
					},
					{
						title: 'site_oddlygood_pl',
						url: 'https://oddlygood.com/pl/',
					},
					{
						title: 'site_oddlygood_es',
						url: 'https://oddlygood.com/es/',
					},
					{
						title: 'site_oddlygood_se',
						url: 'https://oddlygood.com/se/',
					},
				],
			},
		},
	},
	[SiteId.ogUs]: {
		Oddlygood: {
			...oddlyGoodDefaults(theme, isFlattened),
			enableSubNavigationSelection: true,
			siteSelector: {
				currentSiteTitleId: 'site_oddlygood_united_states',
				icon: <WorldIcon />,
				sites: [
					{
						title: 'site_oddlygood_international',
						url: 'https://oddlygood.com/',
					},
					{
						title: 'site_oddlygood_ee',
						url: 'https://oddlygood.com/ee/',
					},
					{
						title: 'site_oddlygood_fi',
						url: 'https://oddlygood.com/fi/',
					},
					{
						title: 'site_oddlygood_nl',
						url: 'https://oddlygood.com/nl/',
					},
					{
						title: 'site_oddlygood_pl',
						url: 'https://oddlygood.com/pl/',
					},
					{
						title: 'site_oddlygood_es',
						url: 'https://oddlygood.com/es/',
					},
					{
						title: 'site_oddlygood_se',
						url: 'https://oddlygood.com/se/',
					},
					{
						title: 'site_oddlygood_uk',
						url: 'https://oddlygood.com/uk/',
					},
				],
			},
		},
	},
	[SiteId.ogFi]: {
		Oddlygood: {
			...oddlyGoodDefaults(theme, isFlattened),
			enableSubNavigationSelection: true,
			siteSelector: {
				currentSiteTitleId: 'site_oddlygood_fi',
				icon: <WorldIcon />,
				sites: [
					{
						title: 'site_oddlygood_international',
						url: 'https://oddlygood.com/',
					},
					{
						title: 'site_oddlygood_ee',
						url: 'https://oddlygood.com/ee/',
					},
					{
						title: 'site_oddlygood_nl',
						url: 'https://oddlygood.com/nl/',
					},
					{
						title: 'site_oddlygood_pl',
						url: 'https://oddlygood.com/pl/',
					},
					{
						title: 'site_oddlygood_es',
						url: 'https://oddlygood.com/es/',
					},
					{
						title: 'site_oddlygood_se',
						url: 'https://oddlygood.com/se/',
					},
					{
						title: 'site_oddlygood_uk',
						url: 'https://oddlygood.com/uk/',
					},
				],
			},
		},
	},
	[SiteId.ogSe]: {
		Oddlygood: {
			...oddlyGoodDefaults(theme, isFlattened),
			enableSubNavigationSelection: true,
			siteSelector: {
				currentSiteTitleId: 'site_oddlygood_se',
				icon: <WorldIcon />,
				sites: [
					{
						title: 'site_oddlygood_international',
						url: 'https://oddlygood.com/',
					},
					{
						title: 'site_oddlygood_ee',
						url: 'https://oddlygood.com/ee/',
					},
					{
						title: 'site_oddlygood_fi',
						url: 'https://oddlygood.com/fi/',
					},
					{
						title: 'site_oddlygood_nl',
						url: 'https://oddlygood.com/nl/',
					},
					{
						title: 'site_oddlygood_pl',
						url: 'https://oddlygood.com/pl/',
					},
					{
						title: 'site_oddlygood_es',
						url: 'https://oddlygood.com/es/',
					},
					{
						title: 'site_oddlygood_uk',
						url: 'https://oddlygood.com/uk/',
					},
				],
			},
		},
	},
	[SiteId.ogEe]: {
		Oddlygood: {
			...oddlyGoodDefaults(theme, isFlattened),
			enableSubNavigationSelection: true,
			siteSelector: {
				currentSiteTitleId: 'site_oddlygood_ee',
				icon: <WorldIcon />,
				sites: [
					{
						title: 'site_oddlygood_international',
						url: 'https://oddlygood.com/',
					},
					{
						title: 'site_oddlygood_fi',
						url: 'https://oddlygood.com/fi/',
					},
					{
						title: 'site_oddlygood_nl',
						url: 'https://oddlygood.com/nl/',
					},
					{
						title: 'site_oddlygood_pl',
						url: 'https://oddlygood.com/pl/',
					},
					{
						title: 'site_oddlygood_es',
						url: 'https://oddlygood.com/es/',
					},
					{
						title: 'site_oddlygood_se',
						url: 'https://oddlygood.com/se/',
					},
					{
						title: 'site_oddlygood_uk',
						url: 'https://oddlygood.com/uk/',
					},
				],
			},
		},
	},
	[SiteId.ogNl]: {
		Oddlygood: {
			...oddlyGoodDefaults(theme, isFlattened),
			enableSubNavigationSelection: true,
			siteSelector: {
				currentSiteTitleId: 'site_oddlygood_nl',
				icon: <WorldIcon />,
				sites: [
					{
						title: 'site_oddlygood_international',
						url: 'https://oddlygood.com/',
					},
					{
						title: 'site_oddlygood_ee',
						url: 'https://oddlygood.com/ee/',
					},
					{
						title: 'site_oddlygood_fi',
						url: 'https://oddlygood.com/fi/',
					},
					{
						title: 'site_oddlygood_pl',
						url: 'https://oddlygood.com/pl/',
					},
					{
						title: 'site_oddlygood_es',
						url: 'https://oddlygood.com/es/',
					},
					{
						title: 'site_oddlygood_se',
						url: 'https://oddlygood.com/se/',
					},
					{
						title: 'site_oddlygood_uk',
						url: 'https://oddlygood.com/uk/',
					},
				],
			},
		},
	},
	[SiteId.ogEs]: {
		Oddlygood: {
			...oddlyGoodDefaults(theme, isFlattened),
			enableSubNavigationSelection: true,
			siteSelector: {
				currentSiteTitleId: 'site_oddlygood_es',
				icon: <WorldIcon />,
				sites: [
					{
						title: 'site_oddlygood_international',
						url: 'https://oddlygood.com/',
					},
					{
						title: 'site_oddlygood_ee',
						url: 'https://oddlygood.com/ee/',
					},
					{
						title: 'site_oddlygood_fi',
						url: 'https://oddlygood.com/fi/',
					},
					{
						title: 'site_oddlygood_nl',
						url: 'https://oddlygood.com/nl/',
					},
					{
						title: 'site_oddlygood_pl',
						url: 'https://oddlygood.com/pl/',
					},
					{
						title: 'site_oddlygood_se',
						url: 'https://oddlygood.com/se/',
					},
					{
						title: 'site_oddlygood_uk',
						url: 'https://oddlygood.com/uk/',
					},
				],
			},
		},
	},
	[SiteId.ogIe]: {
		Oddlygood: {
			...oddlyGoodDefaults(theme, isFlattened),
			enableSubNavigationSelection: true,
			siteSelector: {
				currentSiteTitleId: 'site_oddlygood_ie',
				icon: <WorldIcon />,
				sites: [
					{
						title: 'site_oddlygood_international',
						url: 'https://oddlygood.com/',
					},
					{
						title: 'site_oddlygood_uk',
						url: 'https://oddlygood.com/uk/',
					},
				],
			},
		},
	},
	[SiteId.ogPl]: {
		Oddlygood: {
			...oddlyGoodDefaults(theme, isFlattened),
			enableSubNavigationSelection: true,
			siteSelector: {
				currentSiteTitleId: 'site_oddlygood_pl',
				icon: <WorldIcon />,
				sites: [
					{
						title: 'site_oddlygood_international',
						url: 'https://oddlygood.com/',
					},
					{
						title: 'site_oddlygood_ee',
						url: 'https://oddlygood.com/ee/',
					},
					{
						title: 'site_oddlygood_fi',
						url: 'https://oddlygood.com/fi/',
					},
					{
						title: 'site_oddlygood_nl',
						url: 'https://oddlygood.com/nl/',
					},
					{
						title: 'site_oddlygood_es',
						url: 'https://oddlygood.com/es/',
					},
					{
						title: 'site_oddlygood_se',
						url: 'https://oddlygood.com/se/',
					},
					{
						title: 'site_oddlygood_uk',
						url: 'https://oddlygood.com/uk/',
					},
				],
			},
		},
	},
	[SiteId.mjm]: {
		CountrySite: {
			...navDefaults(theme),
			logo: 'maitojame' as LogoType,
			logoSize: 'large' as LogoSize,
			sectionLinks: undefined,
			links: [
				{
					url: '/haku/',
					title: 'component_comnavigation_search',
					icon: <SearchIcon />,
				},
			],
			showMobileCountrySitesLink: false,
			navLinkProps: defaultSiteLinkProps(theme),
		},
	},
	[SiteId.majal]: {
		CountrySite: {
			...navDefaults(theme),
			bgColor: theme.colors.brandPrimary,
			logo: 'maidonjalostajat',
			logoSize: 'large',
			sectionLinks: undefined,
		},
	},
	[SiteId.finc]: {
		FinlandiaCheese: {
			...navDefaults(theme),
			title: 'component_comnavigation_finlandiacheese_title',
			hideTitleOnMobile: true,
			logo: 'finlandiacheese',
			logoSize: 'large',
			bgColor: theme.colors.white,
			textColor: theme.colors.brandPrimary,
			mobileTopNavBgColor: theme.colors.navigationHeaderSecondaryBackground,
			sectionLinks: [
				{
					url: 'https://www.instacart.com/',
					title: 'component_comnavigation_shop_at',
					isExternal: true,
					bgColor: theme.colors.navigationHighlight,
					textColor: theme.colors.white,
					icon: <InstacartIconWithText />,
				},
			],
			mobileSectionLinks: [
				{
					url: 'https://www.instacart.com/',
					title: 'component_comnavigation_shop_at',
					isExternal: true,
					bgColor: theme.colors.navigationHighlight,
					textColor: theme.colors.white,
					icon: <InstacartIconWithText />,
				},
			],
			links: [
				{
					url: '/contact-us/',
					title: 'component_comnavigation_contactus',
					icon: <ContactIcon />,
				},
			],
			showMobileCountrySitesLink: false,
			navLinkProps: {
				activeItemBorderColor: theme.colors.white,
				mobileActiveItemColor: theme.colors.white,
				mobileItemColor: theme.colors.white,
			},
		},
	},
	[SiteId.ee]: {
		CountrySite: {
			...baltiaSiteDefaults(theme, comSiteUrl),
			siteSelector: {
				currentSiteTitleId: 'site_estonia_language',
				icon: <WorldIcon />,
				bgColor: theme.colors.navigationHeaderBackground,
				sites: [
					{
						title: 'site_estonia_language_english',
						url: '/en/',
					},
					{
						title: 'site_estonia_language_russia',
						url: '/ru/',
					},
					{
						title: 'site_estonia_language_export',
						url: '/exp/',
					},
				],
			},
		},
	},
	[SiteId.eeEn]: {
		CountrySite: {
			...baltiaSiteDefaults(theme, comSiteUrl),
			siteSelector: {
				currentSiteTitleId: 'site_estonia_language_english',
				icon: <WorldIcon />,
				bgColor: theme.colors.navigationHeaderBackground,
				sites: [
					{
						title: 'site_estonia_language',
						url: '/',
					},
					{
						title: 'site_estonia_language_russia',
						url: '/ru/',
					},
					{
						title: 'site_estonia_language_export',
						url: '/exp/',
					},
				],
			},
		},
	},
	[SiteId.eeRu]: {
		CountrySite: {
			...baltiaSiteDefaults(theme, comSiteUrl),
			siteSelector: {
				currentSiteTitleId: 'site_estonia_language_russia',
				icon: <WorldIcon />,
				bgColor: theme.colors.navigationHeaderBackground,
				sites: [
					{
						title: 'site_estonia_language',
						url: '/',
					},
					{
						title: 'site_estonia_language_english',
						url: '/en/',
					},

					{
						title: 'site_estonia_language_export',
						url: '/exp/',
					},
				],
			},
		},
	},
	[SiteId.eeExP]: {
		CountrySite: {
			...baltiaSiteDefaults(theme, comSiteUrl),
			siteSelector: {
				currentSiteTitleId: 'site_estonia_language_export',
				icon: <WorldIcon />,
				bgColor: theme.colors.navigationHeaderBackground,
				sites: [
					{
						title: 'site_estonia_language',
						url: '/',
					},
					{
						title: 'site_estonia_language_english',
						url: '/en/',
					},
					{
						title: 'site_estonia_language_russia',
						url: '/ru/',
					},
				],
			},
		},
	},
	[SiteId.lv]: {
		CountrySite: {
			...baltiaSiteDefaults(theme, comSiteUrl),
			siteSelector: {
				currentSiteTitleId: 'site_latvia_language',
				icon: <WorldIcon />,
				bgColor: theme.colors.navigationHeaderBackground,
				sites: [
					{
						title: 'site_latvia_language_english',
						url: '/en/',
					},
					{
						title: 'site_latvia_language_russia',
						url: '/ru/',
					},
				],
			},
		},
	},
	[SiteId.lvRu]: {
		CountrySite: {
			...baltiaSiteDefaults(theme, comSiteUrl),
			siteSelector: {
				currentSiteTitleId: 'site_latvia_language_russia',
				icon: <WorldIcon />,
				bgColor: theme.colors.navigationHeaderBackground,
				sites: [
					{
						title: 'site_latvia_language',
						url: '/',
					},
					{
						title: 'site_latvia_language_english',
						url: '/en/',
					},
				],
			},
		},
	},
	[SiteId.lvEn]: {
		CountrySite: {
			...baltiaSiteDefaults(theme, comSiteUrl),
			siteSelector: {
				currentSiteTitleId: 'site_latvia_language_english',
				icon: <WorldIcon />,
				bgColor: theme.colors.navigationHeaderBackground,
				sites: [
					{
						title: 'site_latvia_language',
						url: '/',
					},
					{
						title: 'site_latvia_language_russia',
						url: '/ru/',
					},
				],
			},
		},
	},
	[SiteId.lt]: {
		CountrySite: {
			...baltiaSiteDefaults(theme, comSiteUrl),
			siteSelector: {
				currentSiteTitleId: 'site_lithuania_language',
				icon: <WorldIcon />,
				bgColor: theme.colors.navigationHeaderBackground,
				sites: [
					{
						title: 'site_lithuania_russia',
						url: 'https://valio.ee/ru/',
					},
					{
						title: 'site_lithuania_estonia',
						url: 'https://valio.ee/',
					},
					{
						title: 'site_lithuania_english',
						url: 'https://valio.ee/en/',
					},
				],
			},
		},
	},
	[SiteId.ltRu]: {
		CountrySite: {
			...baltiaSiteDefaults(theme, comSiteUrl),
			siteSelector: {
				currentSiteTitleId: 'site_lithuania_russia',
				icon: <WorldIcon />,
				bgColor: theme.colors.navigationHeaderBackground,
				sites: [
					{
						title: 'site_lithuania_language',
						url: '/',
					},
					{
						title: 'site_lithuania_estonia',
						url: 'https://valio.ee/',
					},
					{
						title: 'site_lithuania_english',
						url: 'https://valio.ee/en/',
					},
				],
			},
		},
	},
});

export const getNavigationConfig = (
	siteId: string,
	theme: DefaultTheme,
	layout: NavigationLayoutType,
	comSiteUrl: string,
	isFlattened?: boolean
): NavProps | undefined => {
	const config = navigationConfig(theme, comSiteUrl, isFlattened);

	if (config[siteId] && config[siteId][layout]) {
		return config[siteId][layout];
	}

	return undefined;
};

import React from 'react';
import { NavigationItemWithLinks } from '../../services';
import styled, { css } from 'styled-components';
import { CloseIcon, MenuIcon } from 'styleguide/components/Icons';
import { media } from 'styleguide/helpers/media';

const MenuListWrapper = styled.ul<{ isVisibleOnDesktop: boolean }>`
	list-style-type: none;
	margin: 0;
	padding: 0;
	align-items: center;

	${media.desktop<{ isVisibleOnDesktop: boolean }>`
	display: ${props => (props.isVisibleOnDesktop ? 'flex' : 'none')};
	`};
`;

interface MenuListItemProps {
	isExpandable?: boolean;
	isActive?: boolean;
	textColor: string;
	isVisibleOnDesktop?: boolean;
}

const MenuListItem = styled.li<MenuListItemProps>`
	display: none;

	&.menu-toggle {
		display: inline-block;

		${media.desktop<MenuListItemProps>`
			${({ isExpandable, isVisibleOnDesktop }) => {
				if (!isExpandable) {
					return css`
						display: none;
					`;
				}
				if (!isVisibleOnDesktop) {
					return css`
						display: none;
					`;
				}
				return null;
			}}
		`};
	}

	> svg {
		vertical-align: middle;
	}

	border-bottom: ${props => (props.isActive ? `2px solid ${props.textColor}` : `2px solid transparent`)};

	&:hover {
		&:not(.menu-toggle) {
			border-bottom: 2px solid ${props => props.textColor};
		}
		cursor: pointer;
	}

	${media.desktop`
      display: inline-block;
      line-height: 28px;

      margin-left: 36px;
      
  `}
`;

const MenuItemLink = styled.a<{ textColor: string }>`
	${({ theme }) => theme.typography.navigation};
	font-size: 15px;
	color: ${props => props.textColor};
	text-decoration: none;
	display: block;
	height: 100%;
`;

export interface MenuListProps {
	navigationLinks?: NavigationItemWithLinks[];
	textColor: string;
	isMenuExpanded: boolean;
	isExpandable: boolean;
	isVisibleOnDesktop: boolean;
	onMenuToggle: () => void;
}

const MainMenuList: React.FC<MenuListProps> = ({
	textColor,
	onMenuToggle,
	isMenuExpanded,
	isExpandable,
	navigationLinks,
	isVisibleOnDesktop,
}) => {
	const onMenuToggleClicked = () => {
		onMenuToggle();
	};

	const renderMenuLinkItem = (link: NavigationItemWithLinks, index: number) => (
		<MenuListItem isActive={link.isActive} textColor={textColor} key={index}>
			<MenuItemLink href={link.url} textColor={textColor}>
				{link.title}
			</MenuItemLink>
		</MenuListItem>
	);

	return (
		<MenuListWrapper isVisibleOnDesktop={isVisibleOnDesktop}>
			{navigationLinks && navigationLinks.map(renderMenuLinkItem)}
			<MenuListItem
				isExpandable={isExpandable}
				textColor={textColor}
				className="menu-toggle"
				isVisibleOnDesktop={false}>
				{isMenuExpanded ? (
					<CloseIcon color={textColor} size={32} onClick={onMenuToggleClicked} />
				) : (
					<MenuIcon color={textColor} size={32} onClick={onMenuToggleClicked} />
				)}
			</MenuListItem>
		</MenuListWrapper>
	);
};

export default MainMenuList;

import * as React from 'react';
import styled from 'styled-components';
import { media } from 'styleguide/helpers';
import Logo from 'styleguide/components/Logos/Logo';
import { NavigationMenuItemProps } from '../Navigation/interfaces';
import { useIntl } from 'react-intl';
import { SocialUserNameProps } from './interfaces';
import { getFooterConfig, FooterLinkSection } from './footerConfig';
import { addSiteUrlPrefix, SiteId } from '../App/services';
import { ComSocialMediaLinks } from './ComSocialMediaLinks';

const borderColor = 'rgba(255, 255, 255, 0.2)';
const socialIconSize = 28;

const FooterWrapper = styled.footer`
	background-color: ${props => props.theme.colors.brandPrimary};

	@media print {
		display: none;
	}
`;

const FooterContainer = styled.div`
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	align-content: center;
	padding: 32px 24px;
	margin: 0 auto;

	${media.desktop`
    max-width: 1200px;
    flex-flow: row nowrap;
    justify-content: space-between;
	`};
`;

const SectionWrapper = styled.section`
	flex: 0 0 100%;
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	align-content: center;

	${media.desktop`
		flex: 0 0 50%;
		flex-flow: row nowrap;
	`};
`;

const LeftSectionWrapper = styled(SectionWrapper)<{ showLogo: boolean }>``;

const RightSectionWrapper = styled(SectionWrapper)`
	justify-content: flex-end;
`;

const LogoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex: 0 0 100%;
	padding-bottom: 26px;
	border-bottom: 1px solid ${borderColor};

	> a {
		max-width: 99px;
	}

	${media.desktop`
		flex: 0 0 99px;
		border-bottom: 0 none;
		padding: 0;
	`}
`;

const LinkListWrapper = styled.div`
	display: flex;
	align-self: center;
	flex-flow: column;
	flex: 1 1 auto;

	> ul:first-of-type {
		padding: 16px 0 0 0;
	}
	> ul:last-of-type {
		border-bottom: 1px solid ${borderColor};
		padding: 0 0 16px 0;
	}
	${media.desktop`
		flex: 1 1 auto;
		flex-flow: row nowrap;
		> ul:first-of-type {
			margin-left: ${showLogo => (showLogo ? 65 : 0)}px;
			padding: 0;
		}
		> ul:nth-of-type(2) {
			margin-left: 48px;
		}
		> ul:last-of-type {
			border-bottom: 0 none;
			padding: 0;
		}
	`}
`;

const LinkList = styled.ul`
	display: flex;
	flex-direction: column;
	list-style-type: none;
	flex: 0 0 100%;
	padding: 0;
	margin: 0;

	${media.desktop`
		flex: 0 1 100%;
		padding: 0;
		align-self: flex-start;
	`}
`;

const LinkItem = styled.li`
	${media.desktop`
		display: inline-block;
	`}

	> a {
		color: ${({ theme }) => theme.colors.white};
		text-decoration: none;
		font-family: ${({ theme }) => theme.fonts.primary};
		font-size: 14px;
		line-height: 1.4;
		font-weight: 600;
		letter-spacing: 1px;

		&:hover {
			text-decoration: underline;
		}
	}
`;

const SocialSectionWrapper = styled.div`
	flex: 0 0 100%;
	border-bottom: 1px solid ${borderColor};
	padding: 16px 0;

	${media.desktop`
		flex: 0 1 100%;
		border-bottom: 0 none;
		margin-right: 56px;
		padding: 0;
		align-self: center;
	`}
`;

const CopyrightWrapper = styled.div`
	flex: 0 0 100%;
	font-family: ${({ theme }) => theme.fonts.primary};
	font-size: 14px;
	font-weight: 300;
	line-height: 1.43;
	color: ${({ theme }) => theme.colors.white};

	${media.desktop`
		flex: 0 0 140px;
		align-self: center;
	`};
`;

interface FooterProps extends SocialUserNameProps {
	siteId: SiteId;
	siteUrlPrefix: string;
	copyright?: string;
}

const ComFooter: React.FC<FooterProps> = ({ siteId, siteUrlPrefix, copyright, ...otherProps }) => {
	const intl = useIntl();

	const renderLink = (link: NavigationMenuItemProps, index: number) => (
		<LinkItem key={index}>
			<a
				href={addSiteUrlPrefix(siteUrlPrefix, link.url)}
				target={link.isExternal ? '_blank' : undefined}
				rel={link.isExternal ? 'noopener noreferrer' : undefined}>
				{intl.formatMessage({ id: link.title })} ›
			</a>
		</LinkItem>
	);

	function renderSection(linkSection: FooterLinkSection, index: number) {
		return <LinkList key={index}>{linkSection.links.map(renderLink)}</LinkList>;
	}

	const footerConfig = getFooterConfig(siteId);
	if (!footerConfig) {
		return null;
	}

	const { showLogo, logo, linkSections, showCopyright } = footerConfig;

	return (
		<FooterWrapper>
			<FooterContainer>
				<LeftSectionWrapper showLogo={showLogo}>
					{showLogo && (
						<LogoWrapper>
							<Logo logo={logo} disableScaling={true} logoSize={'99px'} href={siteUrlPrefix} />
						</LogoWrapper>
					)}
					<LinkListWrapper>{linkSections.map(renderSection)}</LinkListWrapper>
				</LeftSectionWrapper>
				<RightSectionWrapper>
					<SocialSectionWrapper>
						<ComSocialMediaLinks socialIconSize={socialIconSize} {...otherProps} />
					</SocialSectionWrapper>
					{showCopyright && (
						<CopyrightWrapper>
							<p>{intl.formatMessage({ id: copyright || 'component_comfooter_copyright' })}</p>
						</CopyrightWrapper>
					)}
				</RightSectionWrapper>
			</FooterContainer>
		</FooterWrapper>
	);
};

export default ComFooter;

import React from 'react';
import styled from 'styled-components';
import { ArrowDownIcon, ArrowUpIcon } from 'styleguide/components/Icons';
import { NavigationMenuItemProps } from '../../interfaces';
import { NavigationItemWithLinks } from '../../services';
import { lighten } from 'utils/color';
import { media } from 'styleguide/helpers/media';

const SubMenuListWrapper = styled.ul<SubMenuListState>`
	list-style-type: none;
	margin: 0 17px;
	padding: ${props => (props.isExpanded ? '16px 0' : 0)};
	overflow: hidden;
	transition: all ease-out 250ms;
	transform-origin: top;
	max-height: ${props => (props.isExpanded ? '100vh' : 0)};

	${media.desktop`
    max-height: 100%;
    margin: 0;
    padding: 16px 0;
  `}
`;

const SubMenuListItem = styled.li`
	display: block;
	line-height: 24px;
`;

const SubMenuLink = styled.a<{ textColor: string; isActive?: boolean }>`
	font-size: 14px;
	font-weight: 600;
	color: ${props => lighten(props.textColor, -20)};
	font-family: ${({ theme }) => theme.fonts.primary};
	text-decoration: none;

	${media.desktop<{ isActive?: boolean }>`
		color: ${({ theme, isActive }) => (isActive ? theme.colors.brandPrimary : theme.colors.linkText)} !important;
		
		&:hover {
			color: ${({ theme }) => theme.colors.brandPrimary} !important; 
		}
  `}
`;

const MenuListItemButton = styled.div<{ textColor: string }>`
	font-size: 18px;
	font-weight: 800;
	position: relative;
	font-family: ${({ theme }) => theme.fonts.primary};

	a {
		color: ${props => props.textColor};
		text-decoration: none;
	}

	svg {
		vertical-align: middle;
		position: absolute;
		right: 0;
	}

	${media.desktop`
		font-size: 15px; 
		a {
    	color: ${({ theme }) => theme.colors.brandPrimary};
		}
    svg {
      display: none;
    }
  `}
`;

export interface SubMenuListProps extends NavigationItemWithLinks {
	textColor: string;
}

interface SubMenuListState {
	isExpanded: boolean;
}

class SubMenuList extends React.Component<SubMenuListProps, SubMenuListState> {
	constructor(props: SubMenuListProps) {
		super(props);
		this.state = { isExpanded: false };
	}
	public render() {
		const { title, textColor, links, url } = this.props;
		const { isExpanded } = this.state;
		return (
			<>
				<MenuListItemButton textColor={textColor}>
					<a href={url}>{title}</a>
					{links.length > 0 && (
						<>
							{isExpanded ? (
								<ArrowUpIcon color={textColor} onClick={this.onMenuToggle} />
							) : (
								<ArrowDownIcon color={textColor} onClick={this.onMenuToggle} />
							)}
						</>
					)}
				</MenuListItemButton>
				<SubMenuListWrapper isExpanded={isExpanded}>{links.map(this.renderChildItems)}</SubMenuListWrapper>
			</>
		);
	}

	private renderChildItems = (item: NavigationMenuItemProps, index: number) => {
		const { textColor } = this.props;
		return (
			<SubMenuListItem key={index}>
				<SubMenuLink href={item.url} textColor={textColor} isActive={item.isActive}>
					{item.title}
				</SubMenuLink>
			</SubMenuListItem>
		);
	};

	private onMenuToggle = () => {
		const { isExpanded } = this.state;
		this.setState({ isExpanded: !isExpanded });
	};
}

export default SubMenuList;

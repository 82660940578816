import React from 'react';
import throttle from 'lodash/throttle';
import styled, { withTheme, DefaultTheme, css, LogoHeight, ThemeContext } from 'styled-components';
import { media, mediaBreakpoints, withWindow } from 'styleguide/helpers';
import { HeaderState } from '../Dom/reducers';
import { connect, MapStateToProps } from 'react-redux';
import { State } from 'common/reducers';
import { navigationActions, NavigationState, NavigationLayoutType } from './reducers';
import injectRoutable, { InjectedRoutableProps } from '../Routing/Routable';
import Logo from 'styleguide/components/Logos/Logo';
import {
	getSiteUrlPrefixOrDefault,
	getSiteUrlId,
	getSite,
	addSiteUrlPrefix,
	SiteId,
	getSiteEnvironment,
	getSiteDomain,
	getSiteUrl,
	getSiteById,
} from '../App/services';
import { CloseIcon, MenuIcon, WorldIcon } from 'styleguide/components/Icons';
import SubMenuList from './components/ComNavigation/SubMenuList';
import { NavigationItem, NavigationItemWithLinks } from './services';
import MainMenuList from './components/ComNavigation/MainMenuList';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import {
	getNavigationConfig,
	NavWithIcon,
	LogoWhiteSpace,
	SiteSelector as SiteSelectorInterface,
	NavProps,
	LogoSize,
} from './components/ComNavigation/ComNavConfig';
import SiteSelector from './components/ComNavigation/SiteSelector';

const SiteNavigationHeight = 85;
const SiteNavigationMobileHeight = 66;
const SiteSectionLinksHeight = 42;
const TinySiteSectionHeight = 8;

const NavigationWrapper = styled.div<{
	bgColor: string;
	isScrolled: boolean;
	isFlattened: boolean;
	isMenuExpanded: boolean;
	hasMobileSectionLinks?: boolean;
}>`
	padding: 0;
	margin-left: auto;
	margin-right: auto;
	transition: margin ease-out 500ms;
	transition: background-color 500ms;
	margin-top: ${props =>
		props.isScrolled && !props.isMenuExpanded && props.hasMobileSectionLinks ? `-${SiteSectionLinksHeight}px` : 0};
	pointer-events: auto;
	background-color: ${props => props.bgColor};

	${props => props.isFlattened && props.theme.styledVariations.comNavigationFlattenedStyle()};

	${media.tablet`
	margin-top: 0;
  `};

	.for-desktop {
		display: none;

		${media.desktop`
		display: flex;
    `}
	}

	${media.desktop<{ isFlattened: boolean }>`	

		height: auto;
		box-shadow: ${props => (props.isFlattened ? '0 2px 4px 0 rgba(0, 0, 0, 0.1)' : 'none')};
		.for-mobile {
			display: none;
		}
  `}
`;

const WidthWrapper = styled.div`
	margin: 0 auto;
	position: relative;
	z-index: ${({ theme }) => theme.zIndices.navigation};

	${media.desktop`
		padding: 0 ${({ theme }) => theme.grid.gutterInPx(2)};
	`};

	@media (min-width: ${({ theme }) => (mediaBreakpoints.desktop1200 + theme.grid.gutter * 4) / 16}em) {
		padding: 0;
	}
`;

const SubMenuWidthWrapper = styled(WidthWrapper)<{ bgColor: string }>`
	max-width: 940px;
	z-index: ${({ theme }) => theme.zIndices.headerWithSearchBox};
	background-color: ${props => props.bgColor};

	${media.desktop`
    margin-bottom: 0;
    background-color: transparent;
  `}
`;

const SiteSectionWrapper = styled.ul<{ isFlattened: boolean; mobileBgColor?: string }>`
	display: none;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-content: flex-start;
	list-style-type: none;
	margin: 0;
	padding: 0;
	background-color: ${props => (props.mobileBgColor ? props.mobileBgColor : 'transparent')};
	height: ${SiteSectionLinksHeight}px;

	${media.desktop<{ isFlattened: boolean }>`
		background-color: transparent;
		position: relative;
		float: right;
    min-width: 500px;
		display: flex;
		margin: 0 ${({ theme }) => theme.grid.gutterInPx(5)} 0 0;

		$:after {
			clear: both;
		}
	`}
`;

const MobileSiteSectionWrapper = styled(SiteSectionWrapper)`
	display: flex;

	${media.desktop`
		display: none;
	`};
`;

const SiteSectionListItem = styled.li<{
	active: boolean;
	borderColor?: string;
	activeBorderColor?: string;
	mobileActiveColor?: string;
}>`
	flex: 1 1 auto;
	margin: 0;
	padding: 0;
	height: ${SiteSectionLinksHeight}px;

	box-shadow: ${props =>
		props.active && props.mobileActiveColor
			? `inset 0 -3px ${props.mobileActiveColor}`
			: props.borderColor
			? `inset 0 -1px ${props.borderColor}`
			: 'none'};

	${media.desktop<{ active: boolean; activeBorderColor?: string }>`
			box-shadow: ${props => (props.active && props.activeBorderColor ? `inset 0 3px ${props.activeBorderColor}` : 'none')};
		`}
`;

const SiteSectionLink = styled.a<{
	textColor: string;
	active: boolean;
	numberOfLinks?: number;
	borderColor?: string;
}>`
	display: flex;
	align-items: center;
	justify-content: ${props => (props.numberOfLinks && props.numberOfLinks === 1 ? 'flex-end' : 'center')};
	font-size: 14px;
	font-weight: 600;
	padding: 12px ${props => (props.numberOfLinks && props.numberOfLinks === 1 ? '20' : '10')}px;
	font-family: ${({ theme }) => theme.fonts.primary};
	color: ${props =>
		props.active && props.borderColor ? props.textColor : props.borderColor ? props.borderColor : props.textColor};
	text-decoration: none;

	${media.desktop`
		padding: 12px 10px;
		justify-content: flex-end;
    font-size: 14px;
		line-height: 17px;
		background-color: transparent;
		color: ${({ theme }) => theme.colors.white};
  `}

	> svg {
		width: 16px;
		height: 16px;
		vertical-align: sub;
		margin: 0 0 0 6px;

		order: 2;

		path {
			fill: ${({ theme }) => theme.colors.white};
		}
	}
	> img {
		order: 2;
		margin: -6px 0 0 8px;
		width: 86px;
		height: 17px;
	}
`;

const CountrySitesMobileLink = styled(SiteSectionLink)`
	display: block;
	font-size: 16px;
	line-height: 1;
	padding: 18px 21px 18px 21px;
	background-color: ${({ theme }) => theme.colors.navigationHighlight};
	color: ${({ theme }) => theme.colors.white};

	> svg {
		margin: 0 12px 0 0;
		width: 21px;
		height: 21px;
	}
`;

const NavigationContainer = styled.div<{ bgColor: string; isFlattened: boolean }>`
	display: flex;
	flex-flow: row nowrap;
	padding: 12px 20px;
	background-color: ${props => props.bgColor};
	justify-content: center;
	align-items: center;

	${media.desktop<{ isFlattened: boolean }>`
		padding: ${props => (props.isFlattened ? '10px 0' : '14px  0')};
		margin: 0 ${({ theme }) => theme.grid.gutterInPx(6)} 0 ${({ theme }) => theme.grid.gutterInPx(3)};
    background-color: transparent;
	`}
	
	@media (min-width: ${({ theme }) => (mediaBreakpoints.desktop1200 + theme.grid.gutter * 4) / 16}em) {
		margin: 0 ${({ theme }) => theme.grid.gutterInPx(6)};
	}
`;

interface LogoWrapperProps {
	shrink?: boolean;
	logoSize: LogoSize;
	logoHeight?: LogoHeight;
}

const LogoWrapper = styled.div<LogoWrapperProps>`
	flex: 0 0 65px;
	transition: flex 200ms ease-out;
	height: ${({ logoHeight }) => logoHeight?.mobile ?? 43}px;

	${media.desktop<LogoWrapperProps>`
		flex: 0 0 ${({ shrink, logoSize }) => (shrink ? '80px' : logoSize === 'default' ? '86px' : '103px')};
		height: ${({ logoHeight }) => logoHeight?.desktop ?? 57}px;
  `}
`;

const MenuTogglerWrapper = styled.div`
	margin: 10px 25px 0 0;
	display: none;
	cursor: pointer;
	${media.desktop`
		display: block;
	`}
`;

const DesktopTitleAndNavWrapper = styled.div<{ bgColor: string; isFlattened: boolean; emptyContent: boolean }>`
	display: none;
	width: 100%;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-content: flex-start;
	list-style-type: none;
	margin: 0;
	padding: 0 ${({ theme }) => theme.grid.gutterInPx(2)};
	background-color: ${props => props.bgColor};
	height: ${({ emptyContent }) => (emptyContent ? `${TinySiteSectionHeight}` : `${SiteSectionLinksHeight}`)}px;
	
	${media.desktop<{ isFlattened: boolean }>`
		display: ${props => (props.isFlattened ? 'none' : 'flex')};
	`} 
	
	@media (min-width: ${({ theme }) => (mediaBreakpoints.desktop1200 + theme.grid.gutter * 4) / 16}em) {
		padding: 0;
	}
`;

const TitleAndNavWrapper = styled.div<{ isFlattened: boolean; logoWhiteSpace: LogoWhiteSpace }>`
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-content: center;
	margin-left: ${({ theme, logoWhiteSpace }) =>
		logoWhiteSpace === 'large' ? theme.grid.gutterInPx(3) : theme.grid.gutterInPx(2)};
	width: 100%;

	${media.desktop<{ isFlattened: boolean }>`
		flex-flow: row wrap;	

    ${({ isFlattened }) => {
			if (isFlattened) {
				return css`
					align-content: flex-start;
				`;
			}
			return null;
		}};

	`};
`;

const NavTitle = styled.span<{ textColor: string; isFlattened: boolean }>`
	display: none;
	flex: 1 1 100%;
	align-self: center;
	font-size: 16px;
	height: 20px;
	font-family: ${({ theme }) => theme.fonts.primary};
	color: ${({ theme }) => theme.colors.white};
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;

	${media.desktop<{ isFlattened: boolean }>`
		display: block;
		margin: 0 0 0 ${({ theme }) => theme.grid.gutterInPx(3)};
	`}

	@media (min-width: ${({ theme }) => (mediaBreakpoints.desktop1200 + theme.grid.gutter * 4) / 16}em) {
		margin: 0 0 0 ${({ theme }) => theme.grid.gutterInPx(6)};
	}
`;

const Title = styled.span<{ textColor: string; isFlattened: boolean }>`
	flex: 1 1 100%;
	align-self: center;
	font-size: 13px;
	font-style: italic;
	font-family: ${({ theme }) => theme.fonts.primary};
	color: ${props => props.textColor};
	font-weight: 200;

	${media.desktop<{ isFlattened: boolean }>`
    font-size: 18px;
    margin: 5px 0;
    display: none;
  `}
`;

const Nav = styled.nav`
	flex: 0 0 32px;
	align-self: center;

	${media.desktop`
    flex: 0 1 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-content: center;
  `}
`;

const NavigationMenuContainer = styled.div<ComNavigationState & { isFlattened: boolean; isScrolled: boolean }>`
	max-height: ${({ isMenuExpanded, isFlattened, topOffset }) =>
		isMenuExpanded ? '100vh' : isFlattened ? `71px` : `${topOffset}px`};
	pointer-events: ${({ isMenuExpanded }) => (isMenuExpanded ? 'auto' : `none`)};
	padding-top: ${({ isScrolled, isMenuExpanded, topOffset }) => (isScrolled && !isMenuExpanded ? 71 : topOffset)}px;
	transition: all ease-out 250ms;
	transform-origin: top;
	position: fixed;
	width: 100%;
	top: -1px;
	left: 0;
	overflow: ${props => (props.isMenuExpanded ? 'auto' : `hidden`)};
	box-shadow: ${props => (props.isMenuExpanded ? '0 2px 4px 0 rgba(0, 0, 0, 0.1)' : 'none')};
	z-index: ${({ theme }) => theme.zIndices.headerWithSearchBox};

	${media.desktop<ComNavigationState & { isFlattened: boolean; isScrolled: boolean }>`
    top: ${props => (props.isFlattened ? 77 : props.topOffset)}px;
    max-height: ${props => (props.isMenuExpanded ? '100vh' : `0px`)};
    padding-top: 0;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colors.navigationMenuBackground} !important;
  `}
`;

const MenuList = styled.ul`
	display: flex;
	flex-flow: column;
	justify-content: flex-start;
	list-style-type: none;
	margin: 0;
	padding: 15px 20px;

	${media.desktop`
    flex-flow: row nowrap;
    padding: 42px 0;
  `}
`;

const MenuListItem = styled.li`
	flex: 1 1 100%;
	& + & {
		margin: 16px 0 0 0;
	}

	${media.desktop`
    flex: 1 1 auto;

    & + & {
      margin: 0 0 0 36px;
    }
  `}
`;

const ContextualSiteList = styled.div`
	display: flex;
	justify-content: space-between;

	> div {
		margin: 0 0 0 36px;
	}
`;

const ContextualLinksList = styled(MenuList)`
	${media.desktop`
    flex: 0 0 auto;
    align-self: center;
    padding: 0;
  `}
`;
const ContextualLinkListItem = styled(MenuListItem)`
	margin-top: 0;
`;
const ContextualLink = styled.a<{ textColor: string }>`
	display: flex;
	font-size: 16px;
	font-weight: 600;
	align-items: center;

	font-family: ${({ theme }) => theme.fonts.primary};
	color: ${props => props.textColor};
	text-decoration: none;
	> svg {
		vertical-align: text-bottom;
		margin: 0 12px 0 0;
	}

	${media.desktop`
		> span {
			order: 1;
		}
		> svg {
			order: 2;
			margin: 0 0 0 12px;
		}
  `}
`;

interface NavigationProps {
	layout: NavigationLayoutType;
	hasResourceErrors: boolean;
}

interface ThemeProps {
	theme: DefaultTheme;
}

interface NavigationStateProps {
	navigation: NavigationState;
	header: HeaderState;
	siteId?: string;
	siteUrlPrefix: string;
	siteUrlId: string;
	siteUrl?: string;
	comSiteUrl: string;
	pathName: string;
}

interface ComNavigationState {
	isMenuExpanded: boolean;
	topOffset: number;
}

type NavigationUnionProps = NavigationProps &
	NavigationStateProps &
	NavigationDispatchProps &
	InjectedRoutableProps &
	ThemeProps &
	WrappedComponentProps;

class ComNavigation extends React.Component<NavigationUnionProps, ComNavigationState> {
	private onResize = throttle(() => withWindow(this.onWindowResize), 250);

	constructor(props: NavigationUnionProps) {
		super(props);
		this.state = {
			isMenuExpanded: false,
			topOffset: 0,
		};
	}

	public componentDidMount() {
		withWindow(w => {
			this.onWindowResize(w);
			w.addEventListener('resize', this.onResize);
		});
	}

	public componentWillUnmount() {
		withWindow(w => {
			w.removeEventListener('resize', this.onResize);
		});
	}

	public render() {
		const { isMenuExpanded } = this.state;
		const {
			siteId,
			siteUrlPrefix,
			header: { isFlattened, isScrolled },
			navigation: { primaryNavigation },
			intl,
			hasResourceErrors,
			layout,
			theme,
			comSiteUrl,
			siteUrl,
			pathName,
		} = this.props;

		if (!siteId) {
			return null;
		}

		const navigationConfig = getNavigationConfig(siteId, theme, layout, comSiteUrl, isFlattened);

		if (!navigationConfig) {
			return null;
		}

		const {
			bgColor,
			topNavBgColor,
			mobileTopNavBgColor,
			textColor,
			title,
			hideTitleOnMobile,
			sectionLinks,
			mobileSectionLinks,
			links,
			logo,
			logoSize,
			navLinkProps,
			showNavigationTree,
			enableSubNavigationSelection,
			mobileDisableActiveLinkStyle,
			invertLogo,
		} = navigationConfig;

		const showNavigation = !hasResourceErrors && !!bgColor && !!textColor;

		const activeUrl = this.getActiveLink(sectionLinks, siteUrlPrefix, navigationConfig, siteUrl, pathName);
		const mobileActiveUrl = this.getActiveLink(mobileSectionLinks, siteUrlPrefix, navigationConfig, siteUrl, pathName);

		const emptyTopNavContent = !title && (!sectionLinks || sectionLinks.length === 0);

		return (
			<ThemeContext.Consumer>
				{themeCtx =>
					showNavigation && (
						<NavigationWrapper
							bgColor={bgColor}
							isFlattened={isFlattened}
							isScrolled={isScrolled}
							isMenuExpanded={isMenuExpanded}
							hasMobileSectionLinks={mobileSectionLinks && 0 < mobileSectionLinks.length}>
							{topNavBgColor && (
								<DesktopTitleAndNavWrapper
									bgColor={topNavBgColor}
									isFlattened={isFlattened}
									emptyContent={emptyTopNavContent}>
									<NavTitle textColor={navigationConfig.textColor} isFlattened={isFlattened}>
										{title && intl.formatMessage({ id: title })}
									</NavTitle>
									{sectionLinks && sectionLinks.length > 0 && (
										<SiteSectionWrapper isFlattened={isFlattened}>
											{sectionLinks.map(sectionLink => (
												<SiteSectionListItem
													key={`section-link-${sectionLink.url}`}
													active={this.isItemLinkActive(activeUrl, sectionLink)}
													borderColor={navLinkProps?.mobileItemColor}
													activeBorderColor={navLinkProps?.activeItemBorderColor}
													mobileActiveColor={navLinkProps?.mobileActiveItemColor}>
													<SiteSectionLink
														href={addSiteUrlPrefix(siteUrlPrefix, sectionLink.url)}
														{...navigationConfig}
														{...sectionLink}
														title={intl.formatMessage({ id: sectionLink.title })}
														active={this.isItemLinkActive(activeUrl, sectionLink)}
														numberOfLinks={sectionLinks.length}
														borderColor={navLinkProps?.mobileItemColor}
														target={sectionLink.isExternal ? '_blank' : undefined}
														rel={sectionLink.isExternal ? 'noopener noreferrer' : undefined}>
														{intl.formatMessage({ id: sectionLink.title })}
														{sectionLink.icon && sectionLink.icon}
													</SiteSectionLink>
												</SiteSectionListItem>
											))}
										</SiteSectionWrapper>
									)}
								</DesktopTitleAndNavWrapper>
							)}
							<WidthWrapper>
								{mobileSectionLinks && mobileSectionLinks.length > 0 && (
									<MobileSiteSectionWrapper isFlattened={isFlattened} mobileBgColor={mobileTopNavBgColor}>
										{mobileSectionLinks?.map(sectionLink => (
											<SiteSectionListItem
												key={`mobile-link-${sectionLink.url}`}
												active={this.isItemLinkActive(mobileActiveUrl, sectionLink, mobileDisableActiveLinkStyle)}
												borderColor={navLinkProps?.mobileItemColor}
												activeBorderColor={navLinkProps?.activeItemBorderColor}
												mobileActiveColor={navLinkProps?.mobileActiveItemColor}>
												<SiteSectionLink
													href={addSiteUrlPrefix(siteUrlPrefix, sectionLink.url)}
													{...sectionLink}
													title={intl.formatMessage({ id: sectionLink.title })}
													active={this.isItemLinkActive(mobileActiveUrl, sectionLink, mobileDisableActiveLinkStyle)}
													numberOfLinks={mobileSectionLinks.length}
													borderColor={navLinkProps?.mobileItemColor}
													target={sectionLink.isExternal ? '_blank' : undefined}
													rel={sectionLink.isExternal ? 'noopener noreferrer' : undefined}>
													{intl.formatMessage({ id: sectionLink.title })}
													{sectionLink.icon && sectionLink.icon}
												</SiteSectionLink>
											</SiteSectionListItem>
										))}
									</MobileSiteSectionWrapper>
								)}
								<NavigationContainer bgColor={bgColor} isFlattened={isFlattened}>
									{showNavigationTree && enableSubNavigationSelection && (
										<MenuTogglerWrapper>
											{isMenuExpanded ? (
												<CloseIcon color={textColor} size={32} onClick={this.onMenuToggle} />
											) : (
												<MenuIcon color={textColor} size={32} onClick={this.onMenuToggle} />
											)}
										</MenuTogglerWrapper>
									)}
									<LogoWrapper
										shrink={navigationConfig.shrinkLogoOnScroll && isFlattened}
										logoSize={logoSize}
										logoHeight={themeCtx?.styledVariations?.siteLogoHeight}>
										<Logo
											logoSize={logoSize === 'default' ? '86px' : '103px'}
											href={(siteUrlPrefix + (navigationConfig.logoLink || '')).replace('//', '/')}
											logo={logo}
											inverted={invertLogo ?? true}
										/>
									</LogoWrapper>
									<TitleAndNavWrapper isFlattened={isFlattened} logoWhiteSpace={navigationConfig.logoWhiteSpace}>
										<Title textColor={navigationConfig.textColor} isFlattened={isFlattened}>
											{title && !hideTitleOnMobile && intl.formatMessage({ id: title })}
										</Title>
										<Nav>
											<MainMenuList
												navigationLinks={primaryNavigation}
												isMenuExpanded={isMenuExpanded}
												isExpandable={this.isMenuExpandable()}
												onMenuToggle={this.onMenuToggle}
												textColor={textColor}
												isVisibleOnDesktop={showNavigationTree}
											/>
											<ContextualSiteList>
												{this.renderSiteContextualLinks(links || [], textColor, 'for-desktop', siteUrlPrefix)}
												{this.renderSiteSelector('for-desktop', navigationConfig.siteSelector)}
											</ContextualSiteList>
										</Nav>
									</TitleAndNavWrapper>
								</NavigationContainer>
							</WidthWrapper>
							<NavigationMenuContainer
								topOffset={this.state.topOffset}
								isScrolled={isScrolled}
								isFlattened={isFlattened}
								isMenuExpanded={isMenuExpanded}>
								<SubMenuWidthWrapper bgColor={bgColor}>
									{this.renderMenuList(primaryNavigation)}
									{this.renderSiteContextualLinks(links || [], textColor, 'for-mobile', siteUrlPrefix)}
									{this.renderSiteSelector('for-mobile', navigationConfig.siteSelector)}
									{navigationConfig.showMobileCountrySitesLink && (
										<CountrySitesMobileLink
											textColor={textColor}
											href="https://www.valio.com/countries/"
											className="for-mobile"
											active={false}
											borderColor={navLinkProps?.mobileItemColor}>
											<WorldIcon size={21} color={theme.colors.white} />
											{intl.formatMessage({ id: 'component_comnavigation_countrysites' })}
										</CountrySitesMobileLink>
									)}
								</SubMenuWidthWrapper>
							</NavigationMenuContainer>
						</NavigationWrapper>
					)
				}
			</ThemeContext.Consumer>
		);
	}

	private onWindowResize = (w: Window) => {
		const { setTopOffset, siteId, theme, layout, comSiteUrl } = this.props;
		let topOffset = SiteNavigationHeight + SiteSectionLinksHeight;
		let navConfig: NavProps | undefined;

		if (siteId) {
			navConfig = getNavigationConfig(siteId, theme, layout, comSiteUrl);

			if (navConfig) {
				const emptyTopNavContent = !navConfig.title && (!navConfig.sectionLinks || navConfig.sectionLinks.length === 0);
				if (emptyTopNavContent) {
					topOffset -= SiteSectionLinksHeight;
				}
			}
		}
		if (w.innerWidth < mediaBreakpoints.desktop) {
			topOffset = SiteNavigationMobileHeight;
			if (navConfig) {
				const mobileSectionLinks = navConfig.mobileSectionLinks;
				if (mobileSectionLinks) {
					topOffset = SiteNavigationMobileHeight + SiteSectionLinksHeight;
				}
			}
		}

		this.setState({ topOffset } as ComNavigationState);
		setTopOffset(topOffset);
	};

	private renderSiteContextualLinks = (
		links: NavWithIcon[] = [],
		textColor: string,
		className: string = '',
		siteUrlPrefix: string
	) => {
		if (!links || (links && links.length === 0)) {
			return null;
		}

		const { intl } = this.props;
		return (
			<ContextualLinksList className={className}>
				{links.map((l, i) => (
					<ContextualLinkListItem key={i}>
						<ContextualLink
							href={addSiteUrlPrefix(siteUrlPrefix, l.url)}
							textColor={textColor}
							target={l.isExternal ? '_blank' : undefined}
							rel={l.isExternal ? 'noopener noreferrer' : undefined}>
							{React.cloneElement(l.icon, { color: textColor, size: 24 })}
							<span>{intl.formatMessage({ id: l.title })}</span>
						</ContextualLink>
					</ContextualLinkListItem>
				))}
			</ContextualLinksList>
		);
	};

	private renderSiteSelector = (className: string, siteSelector?: SiteSelectorInterface) => {
		if (!siteSelector) {
			return null;
		}
		return <SiteSelector {...siteSelector} className={className} />;
	};

	private onMenuToggle = () => {
		const { isMenuExpanded } = this.state;
		this.setState({ isMenuExpanded: !isMenuExpanded } as ComNavigationState);
	};

	private renderMenuList = (items: NavigationItemWithLinks[]) => <MenuList>{items.map(this.renderMenuLinks)}</MenuList>;

	private renderMenuLinks = (item: NavigationItemWithLinks, index: number) => {
		const { theme, layout, siteId, comSiteUrl } = this.props;

		if (!siteId) {
			return null;
		}

		const navigationConfig = getNavigationConfig(siteId, theme, layout, comSiteUrl);

		if (!navigationConfig) {
			return null;
		}

		return (
			<MenuListItem key={index}>
				<SubMenuList {...item} textColor={navigationConfig.textColor} />
			</MenuListItem>
		);
	};

	private isMenuExpandable = () => {
		const {
			navigation: { primaryNavigation },
		} = this.props;

		return primaryNavigation.findIndex(navItem => navItem.links.length > 0) > -1;
	};

	private isItemLinkActive = (
		activeUrl: string | undefined,
		currentItem: NavigationItem,
		mobileDisableActiveLinkStyle?: boolean
	) => {
		return activeUrl ? (mobileDisableActiveLinkStyle ? false : activeUrl === currentItem.url) : false;
	};

	private getActiveLink = (
		items: NavigationItem[] | undefined,
		siteUrlPrefix: string,
		navigationConfig: NavProps,
		siteUrl: string | undefined,
		pathName: string
	) => {
		if (!items || items.length === 0) {
			return undefined;
		}

		const currentPath = pathName.endsWith('/') ? pathName : pathName + '/';
		let activeLinks = items.filter(i =>
			this.isLinkActive(siteUrl, currentPath, siteUrlPrefix, navigationConfig.logoLink, i.url)
		);

		if (activeLinks.length > 0) {
			return activeLinks[0].url;
		}

		activeLinks = items.filter(i => this.isRootUrl(siteUrl, navigationConfig.logoLink, i.url));

		if (activeLinks.length > 0) {
			return activeLinks[0].url;
		}

		return undefined;
	};

	private combineLinkParts = (linkPrefix: string | undefined, linkPostFix: string) => {
		if (linkPrefix && linkPrefix.endsWith('/') && linkPostFix.startsWith('/')) {
			return linkPrefix.substring(0, linkPrefix.length - 1) + linkPostFix;
		}
		return linkPrefix + linkPostFix;
	};

	private isLinkActive = (
		siteUrl: string | undefined,
		currentUrl: string,
		siteUrlPrefix: string,
		sectionRootLink: string | undefined,
		navLinkUrl: string
	) => {
		const fullSectionUrl = this.combineLinkParts(
			siteUrlPrefix !== '/' ? (siteUrl?.endsWith(siteUrlPrefix) ? siteUrl : siteUrl + siteUrlPrefix) : siteUrl,
			sectionRootLink || ''
		);

		const sectionIsActive = (sectionRootLink && fullSectionUrl === navLinkUrl) || navLinkUrl === sectionRootLink;

		const fullCurrentUrl = this.combineLinkParts(
			siteUrlPrefix !== '/' ? siteUrl?.replace(siteUrlPrefix, '') : siteUrl,
			currentUrl
		);

		const path = navLinkUrl.endsWith('/') ? navLinkUrl : navLinkUrl + '/';

		const currentUrlMatch =
			currentUrl === navLinkUrl ||
			currentUrl === path ||
			siteUrlPrefix + path === currentUrl ||
			fullCurrentUrl === path;

		return sectionIsActive || currentUrlMatch;
	};

	private isRootUrl = (siteUrl: string | undefined, sectionRootLink: string | undefined, navLinkUrl: string) => {
		return siteUrl === navLinkUrl || (navLinkUrl === '/' && sectionRootLink === undefined);
	};
}

const mapStateToProps: MapStateToProps<NavigationStateProps, NavigationProps, State> = ({
	dom,
	app,
	routing,
	navigation,
}: State): NavigationStateProps => {
	const sites = (app.settings && app.settings.sites) || [];
	const site = getSite(routing, sites);
	const siteEnv = getSiteEnvironment(routing, sites);
	const siteDomain = getSiteDomain(routing, sites);
	const comSite = getSiteById(sites, SiteId.com);
	const comSiteUrl = comSite?.siteUrls.find(su => su.environment === siteEnv && su.domain === siteDomain);

	return {
		navigation,
		header: dom.header,
		siteUrl: getSiteUrl(routing, sites),
		siteId: site?.id,
		siteUrlPrefix: getSiteUrlPrefixOrDefault(routing, sites, '/'),
		siteUrlId: getSiteUrlId(routing, sites) || '',
		comSiteUrl: (comSiteUrl && `${comSiteUrl.domain}${comSiteUrl.urlPrefix}`) || 'https://www.valio.com/',
		pathName: routing.pathname,
	};
};

interface NavigationDispatchProps {
	openNavigation: typeof navigationActions.openNavigation;
	closeNavigation: typeof navigationActions.closeNavigation;
	setTopOffset: typeof navigationActions.setTopOffset;
}

export default connect(mapStateToProps, {
	openNavigation: navigationActions.openNavigation,
	closeNavigation: navigationActions.closeNavigation,
	setTopOffset: navigationActions.setTopOffset,
})(injectIntl(withTheme(injectRoutable(ComNavigation))));
